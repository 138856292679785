/* eslint-disable camelcase */
import {
  BsEnvelopeFill,
  BsFacebook,
  BsFillCaretRightFill,
  BsInstagram,
  BsLinkedin,
  BsTelephoneFill,
  BsTwitter,
  BsYoutube,
} from 'react-icons/bs'
import { HiLocationMarker } from 'react-icons/hi'

// import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

import { BASE_URL, IMAGE_BASE_URL } from 'data/base-url'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useQuery } from 'react-query'
import Container from './container'

export default function Footer() {
  const { data, isLoading } = useQuery(['footer'], async () => {
    const response = await window.fetch(`${BASE_URL}/theme`)
    return response.json()
  })

  if (isLoading) {
    return <p>Loading...</p>
  }

  const {
    theme_contact,
    theme_footer,
    theme_logo,
    theme_socialmedia: { facebook, twitter, instra, youtube, linkedin },
  } = data.theme

  return (
    <footer className='bg-slate-900 text-slate-200' id='contact'>
      <Container className=''>
        <div className='grid grid-cols-12 gap-4 py-20'>
          <div className='col-span-12 lg:col-span-4 w-full lg:w-11/12'>
            <div>
              <LazyLoadImage
                src={`${IMAGE_BASE_URL}/${theme_logo?.footer_logo}`}
                effect='blur'
                className='img-fluid'
                loading='lazy'
                alt='footer logo'
              />
            </div>
            <p className='text-lg mt-6 text-justify'>{theme_footer.description}</p>
            <ul className='flex mt-6'>
              <li className='bg-primary p-2 rounded-md mr-2 hover:scale-110 ease-in-out duration-500'>
                <a href={facebook} target='_blank' rel='noreferrer'>
                  <BsFacebook />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href={twitter} target='_blank' rel='noreferrer'>
                  <BsTwitter />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href={instra} target='_blank' rel='noreferrer'>
                  <BsInstagram />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href={youtube} target='_blank' rel='noreferrer'>
                  <BsYoutube />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md ml-2 hover:scale-110 ease-in-out duration-500'>
                <a href={linkedin} target='_blank' rel='noreferrer'>
                  <BsLinkedin />
                </a>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-2'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Helpful Link</h4>
            <ul>
              <li className='mb-1'>
                <Link to='/about/business-model' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Business Model
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/about/leadership-team' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Leadership Team
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/about/management-team' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Management Team
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/our-works' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Our Works
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/our-projects' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Our Projects
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-2'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Segam Group</h4>
            <ul>
              <li className='mb-1'>
                <Link
                  to='/industries/sand-soil-suppliers'
                  className='group flex items-center text-lg'
                >
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Sand & Soil Suppliers
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/industries/excavator' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Excavator
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/industries/land-property' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Land Property
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link
                  to='/industries/building-development'
                  className='group flex items-center text-lg'
                >
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Building Development
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/industries/dum-truck' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Dum Truck
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-4'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Contact info</h4>
            <div className='flex gap-1'>
              <span>
                <HiLocationMarker className='h-6 w-6' />
              </span>
              <span className='text-lg'>{theme_contact.address}</span>
            </div>
            <div className='flex gap-2 items-center my-2'>
              <span>
                <BsTelephoneFill className='h-5 w-5' />
              </span>
              <span className='text-lg'>{theme_contact.phone}</span>
            </div>
            <div className='flex gap-2 items-center'>
              <span>
                <BsEnvelopeFill className='h-5 w-5' />
              </span>
              <span className='text-lg'>{theme_contact.email}</span>
            </div>
          </div>
        </div>
      </Container>
      <div className='py-3 bg-primary/90'>
        <Container>
          <div className='flex flex-col lg:flex-row justify-between items-center'>
            <p className='text-lg text-center lg:text-left order-2 lg:order-1 mt-2 lg:mt-0'>
              Copyright @{new Date().getFullYear()} Designed by Afro Digital & Developed by
              TechUniq@
            </p>
            <ul className='flex items-center flex-wrap gap-x-3 lg:gap-4 justify-evenly order-1 lg:order-2'>
              <li>
                <Link to='/terms-&-conditions' className='text-lg'>
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link to='/privacy-&-policy' className='text-lg'>
                  Privacy & Policy
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </footer>
  )
}
