import React from 'react'
import { Route, Routes } from 'react-router-dom'

const SandSoilSuppliers = React.lazy(() => import('./sand-soil-suppliers'))
const ManagementTeam = React.lazy(() => import('./management-team'))
const LeadershipTeam = React.lazy(() => import('./leadership-team'))
const LandProperty = React.lazy(() => import('./land-property'))
const Excavator = React.lazy(() => import('./excavator'))
const DumTruck = React.lazy(() => import('./dum-truck'))
const Dredger = React.lazy(() => import('./dredger'))
const BusinessModel = React.lazy(() => import('./business-model'))
const BuildingDevelopment = React.lazy(() => import('./building-development'))
const OurWorks = React.lazy(() => import('./our-works'))
const Home = React.lazy(() => import('./home'))
const Projects = React.lazy(() => import('./projects'))
const NotFound = React.lazy(() => import('./not-found'))

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about/business-model' element={<BusinessModel />} />
      <Route path='/about/management-team' element={<ManagementTeam />} />
      <Route path='/about/leadership-team' element={<LeadershipTeam />} />
      <Route path='/our-works' element={<OurWorks />} />
      <Route path='/our-projects' element={<Projects />} />
      <Route path='/industries/sand-soil-suppliers' element={<SandSoilSuppliers />} />
      <Route path='/industries/land-property' element={<LandProperty />} />
      <Route path='/industries/building-development' element={<BuildingDevelopment />} />
      <Route path='/industries/dum-truck' element={<DumTruck />} />
      <Route path='/industries/excavator' element={<Excavator />} />
      <Route path='/industries/dredger' element={<Dredger />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
