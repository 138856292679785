import Container from 'components/container'
import Footer from 'components/footer'
import FullPageSpinner from 'components/full-page-spinner'
import { DASHBOARD_URL } from 'data/base-url'
import Router from 'pages/router'
import React from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'

function App() {
  return (
    <>
      <div className='bg-slate-900'>
        <Container>
          <div className='flex justify-end'>
            <div className='text-white py-5'>
              <a href='#contact' className='mr-4'>
                Contact Us
              </a>
              <a href={`${DASHBOARD_URL}/login`} target='_blank' rel='noreferrer'>
                Login
              </a>
            </div>
          </div>
        </Container>
      </div>
      <React.Suspense fallback={<FullPageSpinner />}>
        <Router />
      </React.Suspense>
      <Footer />
    </>
  )
}

export default App
